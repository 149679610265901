import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "expense_categories",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    expenseCategoriesRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    expenseCategoriesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    expenseCategoriesRequestFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const {
  expenseCategoriesRequested,
  expenseCategoriesReceived,
  expenseCategoriesRequestFailed,
} = slice.actions;

const url = "v1/hrms/expense_categories";

export const getExpenseCategories = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      onStart: expenseCategoriesRequested.type,
      onSuccess: expenseCategoriesReceived.type,
      onError: expenseCategoriesRequestFailed.type,
    })
  );
};
