import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "organization_policies",
  initialState: {
    policies: [],
    loading: false,
    saveLoading: false,
    policySaved: false,
    deleteLoading: false,
    policyDeleted: false,
    policyDocumentDeletionLoading: false,
    policyDocumentDeleted: false,
  },

  reducers: {
    policiesRequested: (state, action) => {
      state.loading = true;
    },

    policiesReceived: (state, action) => {
      state.policies = action.payload.policies;
      state.loading = false;
    },

    policiesRequestFailed: (state, action) => {
      state.loading = false;
    },

    policyCreationRequested: (state, action) => {
      state.saveLoading = true;
      state.policySaved = false;
    },

    policyCreationReceived: (state, action) => {
      const { policy } = action.payload;
      if (policy) {
        state.policies.push(policy);
      }
      state.saveLoading = false;
      state.policySaved = true;
    },

    policyCreationFailed: (state, action) => {
      state.saveLoading = false;
      state.policySaved = false;
    },

    policyUpdationRequested: (state, action) => {
      state.saveLoading = true;
      state.policySaved = false;
    },

    policyUpdationReceived: (state, action) => {
      const { policy } = action.payload;

      const policyIndex = state.policies.findIndex((i) => i.id === policy.id);
      if (policyIndex !== -1) {
        state.policies[policyIndex] = policy;
      }
      state.saveLoading = false;
      state.policySaved = true;
    },

    policyUpdationFailed: (state, action) => {
      state.saveLoading = false;
      state.policySaved = false;
    },

    policyDeletionRequested: (state, action) => {
      state.deleteLoading = true;
      state.policyDeleted = false;
    },

    policyDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const policyIndex = state.policies.findIndex((dep) => dep.id === id);
      state.policies.splice(policyIndex, 1);
      state.deleteLoading = false;
      state.policyDeleted = true;
    },

    policyDeletionFailed: (state, action) => {
      state.deleteLoading = false;
      state.policyDeleted = false;
    },

    resetPolicyActionLoaders: (state, action) => {
      state.policySaved = false;
      state.policyDocumentDeleted = false;
      state.policyDeleted = false;
    },
  },
});

export default slice.reducer;

const {
  policiesRequested,
  policiesReceived,
  policiesRequestFailed,
  policyCreationRequested,
  policyCreationReceived,
  policyCreationFailed,
  policyUpdationRequested,
  policyUpdationReceived,
  policyUpdationFailed,
  policyDeletionRequested,
  policyDeletionReceived,
  policyDeletionFailed,
  resetPolicyActionLoaders,
} = slice.actions;

const url = "v1/hrms/organization_policies";

export const getPolicies = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: policiesRequested.type,
      onSuccess: policiesReceived.type,
      onError: policiesRequestFailed.type,
    })
  );
};

export const createPolicy = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: policyCreationRequested.type,
      onSuccess: policyCreationReceived.type,
      onError: policyCreationFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      successMessage: "Policy Created Successfully!",
      showAlert: true,
      showErrorAlert: true,
    })
  );
};

export const updatePolicy = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.get("id")}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: policyUpdationRequested.type,
      onSuccess: policyUpdationReceived.type,
      onError: policyUpdationFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      successMessage: "Policy Updated Successfully!",
      showAlert: true,
      showErrorAlert: true,
    })
  );
};

export const deletePolicy = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: policyDeletionRequested.type,
      onSuccess: policyDeletionReceived.type,
      onError: policyDeletionFailed.type,
      successMessage: "Policy Deleted Successfully!",
      showAlert: true,
      showErrorAlert: true,
    })
  );
};

export const resetPolicyLoaders = () => (dispatch) => {
  return dispatch(resetPolicyActionLoaders());
};
