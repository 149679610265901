import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { statusesReducer } from "./statuses";
import { statusGroupsReducer } from "./status_groups";
import { ticketsReducer } from "./tickets";
import { usersReducer } from "./users";
import { bucketsReducer } from "./buckets";
import { attachmentsReducer } from "./attachments";
import { metaAttributesReducer } from "./meta_attributes";
import { tagsReducer } from "./tags";
import { alertsReducer } from "./alerts";
import { hrmsReducer } from "./hrms";
import { departmentsReducer } from "./departments";
import { employeeLevelsReducer } from "./employee_levels";
import { teamsReducer } from "./teams";
import { rolesReducer } from "./roles";
import { shiftsReducer } from "./shifts";
import { companyLeavesReducer } from "./company_leaves";
import { holidaysReducer } from "./holidays";
import { commonReducer } from "./common";
import { eventsReducer } from "./events";
import { companyObjectivesReducer } from "./company_objectives";
import { performanceQuestionnairesReducer } from "./performance_questionnaires";
import { performanceSettingsReducer } from "./performance_settings";
import { performanceTimeframesReducer } from "./performance_timeframes";
import { employeesLogsReducer } from "./employees_logs";
import { employeesLeavesReducer } from "./employees_leaves";
import { employeesCompOffsReducer } from "./employees_comp_offs";
import { employeesAttendancesReducer } from "./employees_attendances";
import { employeesPerformancesReducer } from "./employees_performances";
import { organizationPoliciesReducer } from "./organization_policies";
import { financeReducer } from "./finance"
import { companyAllowancesReducer } from "./company_allowances";
import { companyPayrollSettingsReducer } from "./company_payroll_settings";
import { companyBankAccountsReducer } from "./company_bank_accounts";
import { companyVariablePaySettingsReducer } from "./company_variable_pay_settings";
import { companyBonusesReducer } from "./company_bonuses";
import { companyProfessionalTaxesReducer } from "./company_professional_taxes";
import { companyPayrollTimelinesReducer } from "./company_payroll_timelines";
import { expenseCategoriesReducer } from "./expense_categories";
import { employeeExpensesReducer } from "./employee_expenses";
import { request } from "./request";

export default function store() {
  return configureStore({
    reducer: {
      statuses: statusesReducer,
      tickets: ticketsReducer,
      users: usersReducer,
      buckets: bucketsReducer,
      attachments: attachmentsReducer,
      status_groups: statusGroupsReducer,
      meta_attributes: metaAttributesReducer,
      tags: tagsReducer,
      alerts: alertsReducer,
      hrms: hrmsReducer,
      departments: departmentsReducer,
      teams: teamsReducer,
      roles: rolesReducer,
      shifts: shiftsReducer,
      company_leaves: companyLeavesReducer,
      holidays: holidaysReducer,
      common: commonReducer,
      events: eventsReducer,
      company_objectives: companyObjectivesReducer,
      performance_questionnaires: performanceQuestionnairesReducer,
      employee_levels: employeeLevelsReducer,
      performance_settings: performanceSettingsReducer,
      performance_timeframes: performanceTimeframesReducer,
      employees_logs: employeesLogsReducer,
      employees_leaves: employeesLeavesReducer,
      employees_comp_offs: employeesCompOffsReducer,
      employees_attendances: employeesAttendancesReducer,
      employees_performances: employeesPerformancesReducer,
      organization_policies: organizationPoliciesReducer,
      finance: financeReducer,
      company_allowances: companyAllowancesReducer,
      company_payroll_settings: companyPayrollSettingsReducer,
      company_bank_accounts: companyBankAccountsReducer,
      company_variable_pay_settings: companyVariablePaySettingsReducer,
      company_bonuses: companyBonusesReducer,
      company_professional_taxes: companyProfessionalTaxesReducer,
      company_payroll_timelines: companyPayrollTimelinesReducer,
      expense_categories: expenseCategoriesReducer,
      employee_expenses: employeeExpensesReducer,
    },
    middleware: [
      ...getDefaultMiddleware({ serializableCheck: false }),
      request,
    ],
  });
}
