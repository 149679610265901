import cryptoRandomString from "crypto-random-string";
import dayjs from "dayjs";

const ConvertToTitleCase = (txt) => {
  var i, j, str, lowers, uppers;
  str = txt.replace(/_/g, " ").replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\s" + lowers[i] + "\\s", "g"),
      function (txt) {
        return txt.toLowerCase();
      }
    );

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\b" + uppers[i] + "\\b", "g"),
      uppers[i].toUpperCase()
    );

  return str;
};

const flattenArray = (array) => {
  let len = array.length;
  let result = [];
  for (let i = 0; i < len; i++) {
    let currentElement = array[i];

    if (Array.isArray(currentElement)) {
      let flattedArray = flattenArray(currentElement);
      result.push(...flattedArray);
    } else {
      result.push(currentElement);
    }
  }
  return result;
};

const flattenTheList = (arr, childrenKey, depth = Infinity) => {
  let flattenedArray = [];

  arr.forEach((item) => {
    let currentItem = item[childrenKey];
    flattenedArray.push(item);
    if (Array.isArray(currentItem) && depth > 0) {
      flattenedArray = flattenedArray.concat(
        flattenTheList(currentItem, childrenKey, depth - 1)
      );
    } else if (
      typeof currentItem === "object" &&
      currentItem !== null &&
      depth > 0
    ) {
      flattenedArray = flattenedArray.concat(
        flattenTheList(currentItem, childrenKey, depth - 1)
      );
    }
  });

  return flattenedArray;
};

const downloadFile = (url, filename) => {
  fetch(url, { method: "get", mode: "no-cors", referrerPolicy: "no-referrer" })
    .then((res) => res.blob())
    .then((res) => {
      const aElement = document.createElement("a");
      aElement.setAttribute("download", filename);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
};

const getFileObjectUrls = async (files) => {
  const list = [];
  if (files && files.length) {
    const readAllFiles = async () => {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = await readFileAsDataURL(file);
        list.push({
          url: url,
          key: generateUniqKey(),
          file: file,
          filename: file?.name,
        });
      }
    };

    await readAllFiles();
  }
  return list;
};

const readFileAsDataURL = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (readerEvt) {
      const dataURL = readerEvt.target.result;
      resolve(dataURL);
    };
    reader.readAsDataURL(file);
  });
};

const generateUniqKey = () => {
  const key = cryptoRandomString({ length: 40, type: "url-safe" });
  const timestamp = Date.now();
  return `${timestamp}-${key}`;
};

const viewFile = (url, filename) => {
  const aElement = document.createElement("a");
  aElement.setAttribute("download", filename);
  const href = url;
  aElement.href = href;
  aElement.setAttribute("target", "_blank");
  aElement.click();
};

const profileShortName = (name, length = 2) => {
  if (!name) {
    return "ER";
  }
  const words = name.split(" ").filter((word) => /^[A-Za-z]/.test(word)); // Filter out non-alphabetic words

  let avatarName = "";
  if (words.length === 1) {
    return `${words[0][0]}${words[0][1] ? words[0][1] : ""}`.toUpperCase();
  }
  for (const word of words) {
    avatarName += word[0].toUpperCase();
    if (avatarName.length >= length) {
      break;
    }
  }

  return avatarName;
};

const copyToClipboard = (text) => {
  const copyText = () => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  if (navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard
      .writeText(text)
      .then(() => true)
      .catch((err) => {
        console.error("Failed to copy using navigator.clipboard:", err);
        copyText();
        return true;
      });
  } else {
    try {
      copyText();
      return true;
    } catch (err) {
      console.error("Failed to copy using document.execCommand:", err);
      return false;
    }
  }
};

const webSharing = async (content = null) => {
  try {
    if (navigator.share) {
      await navigator.share({
        title: "Check out this awesome website!",
        text: "I found this cool website that you might like.",
        url: content || window.location.href,
      });
    } else {
      alert("Web Share API not supported in this browser.");
    }
  } catch (error) {
    console.error("Error sharing:", error);
  }
};

const groupArraysByKey = (array, key) => {
  const groupedArrays = {};

  array.forEach((item) => {
    const value = item[key];
    if (!groupedArrays[value]) {
      groupedArrays[value] = [item];
    } else {
      groupedArrays[value].push(item);
    }
  });

  return groupedArrays;
};

const containsText = (name, searchTerm) =>
  name && name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

const removeCookie = (cookieName) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() - 1);

  document.cookie = `${cookieName}=; expires=${expirationDate.toUTCString()}; path=/;`;
};

const dateDifference = (startDate, endDate = dayjs(), unit = "years") => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return end.diff(start, unit);
};

const calculateTimeDifference = (
  time1,
  time2,
  isEndTimeSpansToNextDay = false,
  text = ""
) => {
  if (!time1 || !time2) {
    return null;
  }
  const start = dayjs(time1);
  let end = dayjs(time2);

  if (isEndTimeSpansToNextDay && end.isBefore(start)) {
    end = end.add(1, "day");
  }

  let duration = end.diff(start, "minutes");

  if (duration <= 0) {
    return (
      <span style={{ color: "red" }}>
        end time must be greater than the start time
      </span>
    );
  }
  const hours = duration / 60;

  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rminutes < 1) {
    return `${rhours} hrs ${text}`;
  } else {
    return `${rhours} hrs ${rminutes} mins ${text}`;
  }
};

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const convertEmptyObjectToNull = (obj) => {
  if (!obj) {
    return null;
  }

  return isObjectEmpty(obj) ? null : obj;
};

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const isEmpty = (str) => {
  if (!str) {
    return true;
  } else if (str.trim() === "") {
    return true;
  }
  return false;
};

const capitalizeFirstLetter = (str) => {
  return str ? str[0].toUpperCase() + str.slice(1) : null;
};

const ellipsisStyle = (lineClamp = 2) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
};

function formatNumberToLakhOrCrore(number) {
  if (number >= 10000000) {
    return (number / 10000000).toFixed(2) + " Cr";
  } else if (number >= 100000) {
    return (number / 100000).toFixed(2) + " L";
  } else {
    return new Intl.NumberFormat("en-IN").format(number);
  }
}

const deepEqualObject = (obj1, obj2) => {
  // Check if both arguments are objects
  if (
    typeof obj1 === "object" &&
    obj1 !== null &&
    typeof obj2 === "object" &&
    obj2 !== null
  ) {
    // Check if both objects have the same number of properties
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    // Recursively compare each property
    for (const key in obj1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEqualObject(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  } else {
    return obj1 === obj2;
  }
};

const deepEqualArray = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!deepEqualObject(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
};

const formatDateRange = (startDate, endDate, seperator = "‒") => {
  if (!startDate && endDate) {
    return dayjs(endDate).format("ll");
  }

  if (startDate && !endDate) {
    return dayjs(startDate).format("ll");
  }

  if (!startDate && !endDate) {
    return "-";
  }

  if (startDate === endDate) {
    return dayjs(startDate).format("ll");
  }

  const startYear = dayjs(startDate).year();
  const endYear = dayjs(endDate).year();

  let finalStartDate;

  if (startYear === endYear) {
    finalStartDate = dayjs(startDate).format("MMM DD");
  } else {
    finalStartDate = dayjs(startDate).format("ll");
  }

  const finalEndDate = dayjs(endDate).format("ll");

  return `${finalStartDate} ${seperator} ${finalEndDate}`;
};

const hexToRgba = (hex, alpha = 1) => {
  hex = hex.replace(/^#/, "");

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getOrdinalSuffix = (day) => {
  const j = day % 10,
    k = day % 100;
  if (j === 1 && k !== 11) return `${day}st`;
  if (j === 2 && k !== 12) return `${day}nd`;
  if (j === 3 && k !== 13) return `${day}rd`;
  return `${day}th`;
};

const hasOnlyDigits = (str) => {
  return /^\d+$/.test(str);
};

export {
  groupArraysByKey,
  webSharing,
  copyToClipboard,
  ConvertToTitleCase,
  flattenArray,
  flattenTheList,
  downloadFile,
  viewFile,
  getFileObjectUrls,
  profileShortName,
  containsText,
  removeCookie,
  dateDifference,
  calculateTimeDifference,
  debounce,
  convertEmptyObjectToNull,
  isObjectEmpty,
  capitalizeFirstLetter,
  isEmpty,
  ellipsisStyle,
  formatNumberToLakhOrCrore,
  deepEqualObject,
  deepEqualArray,
  formatDateRange,
  hexToRgba,
  getOrdinalSuffix,
  hasOnlyDigits,
};
